<template>
  <v-card class="ma-5" :elevation="0" color="grey-lighten-4" flat outlined>
    <v-toolbar color="primary" :dark="true">
      <v-toolbar-title>My volunteers</v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-card-text class="pa-3">
      <v-chip-group selected-class="text-primary" column>
        <v-chip
          v-for="m in allowed_campaigns"
          :key="m.id"
          @click="campaign_changed(m)"
        >
          {{ m.name }}
        </v-chip>
      </v-chip-group>
      <v-divider />
      <v-list lines="three">
        <v-list-item v-for="item in volunteers" :key="item.id">
          <div class="row mb-4" style="border-bottom: 1px solid grey">
            <div class="col-3">
              <image-field
                size="mini"
                :disabled="true"
                :show-zoom="false"
                :image-click="true"
                :grid="true"
                :width="0"
                :height="50"
                :value="item.photo"
              />
            </div>
            <div class="col-9">
              <div class="row">
                <div class="col-12 pb-0 title">
                  {{ item.full_name }}
                </div>
                <div
                  class="col-12 pt-0 pb-0"
                  style="max-height: 35px; overflow: hidden"
                >
                  Constituency President
                </div>
                <div
                  class="col-12 pt-0"
                  style="max-height: 35px; overflow: hidden"
                >
                  {{ item.volunteer_voters_count }} Voters
                </div>
              </div>
            </div>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-overlay :value="loading" :opacity="0.7">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import Vue from "vue";
import { Select, Option } from "element-ui";
Vue.use(Select);
Vue.use(Option);
import ImageField from "@/components/custom/ImageField.vue";
export default {
  created() {
    if (this.allowed_campaigns.length == 1) {
      this.campaign_changed(this.allowed_campaigns[0]);
    }
  },
  components: {
    ImageField,
  },
  data() {
    return {
      valid: true,
      loading: false,
      volunteers: [],
      campaigns_id: null,
    };
  },
  methods: {
    campaign_changed(campaign) {
      if (!this.empty(campaign)) {
        this.campaigns_id = campaign.id;
        this.getVolunteers();
      }
    },
    getVolunteers() {
      this.loading = true;
      let f = {
        campaigns_id: this.campaigns_id,
      };
      this.api("public/volunteers", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.volunteers = response.data;
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  name: "Volunteers",
};
</script>
